// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA_7PhFr8JNRRG1IjQI_jnHT9c-Ihczj0Y",
    authDomain: "swap-a0bf0.firebaseapp.com",
    projectId: "swap-a0bf0",
    storageBucket: "swap-a0bf0.appspot.com",
    messagingSenderId: "129352213969",
    appId: "1:129352213969:web:f2c1e71457f864f0b8acf0",
    measurementId: "G-X0RNV4D86C"
  };
// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);