import { useEffect, useState } from 'react';
import './App.css';
import { Button, Form, Input, Table, Tag, FloatButton } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { db } from './firebase';

function App() {
  const pwd = "admin";
  const [isLoggedIn, setLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (timestamp) => {
        if (timestamp.length != 0) {
          var date = new Date(timestamp);
          var iso = date.toISOString().match(/(\d{4}\-\d{2}\-\d{2})T(\d{2}:\d{2}:\d{2})/)
          return <div>{iso[1] + ' ' + iso[2]}</div>
        }
        return <div></div>
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (tag) => {
        let color = 'blue';
        if (tag === 'done') {
          color = "green";
        } else if (tag === 'failed') {
          color = "red";
        }
        return (
          <Tag color={color} key={tag}>
            {tag.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Link',
      key: 'link',
      dataIndex: 'link',
      render: (text) => <a target="_blank" href={text}>{text}</a>,
    },
  ];


  async function fetchData() {
    setLoading(true);
    const colc = collection(db, "user_data");
    const unsub = onSnapshot(query(colc, orderBy('created_at')), (data) => {
      const newData = data.docs.map((doc) => {
        const d = doc.data();
        return {
          key: doc.id,
          id: doc.id,
          status: d.status,
          phone: d.phone,
          link: d.url,
          created_at: d.created_at || "",
        };
      });
      setDataSource(newData);
      setLoading(false);
    });
  }


  const onFinish = (values) => {
    if (values.username != 'root') {
      alert("wrong username!");
      return;
    }

    if (values.password != pwd) {
      alert("wrong password!");
      return;
    }

    setLogin(true);
    fetchData();
  };
  const onFinishFailed = (errorInfo) => { };

  return (
    isLoggedIn === false ? <div className='login-form'>
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your username!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit" shape='round'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div> : <div>
      <FloatButton
        shape="square"
        type="primary"
        style={{
          right: 24,
          width: 50,
          height: 50,
        }}
        icon={<ReloadOutlined />}
        onClick={() => {
          window.location.reload();
        }}
      />
      <Table columns={columns} dataSource={dataSource} loading={loading} pagination={false} />
    </div>
  );
}

export default App;
